import React, {FunctionComponent} from 'react';
import Modal from '../Modal/Modal';

interface Props {
    showPopup: boolean;
    onClose: () => void;
}

const Popup: FunctionComponent<Props> = (
    {
        showPopup,
        onClose,
        children
    }
) => {
    return (
        <Modal onClose={onClose} isShown={showPopup}>
            {children}
        </Modal>
    );
}

export default Popup;
