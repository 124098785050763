import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './Modal.module.scss';
// @ts-ignore
import ReactModal from 'react-modal';
import A11yText from "../A11yText/A11yText";

interface Props {
    isShown: boolean;
    isLoading?: boolean;
    onClickCallback?: () => void;
    onClose: () => void;
}

const Modal: FunctionComponent<Props> =
    ({
         isShown = false,
         isLoading,
         onClickCallback = () => {
         },
         onClose = () => {
         },
         children
     }) => {
        useEffect(() => {
            ReactModal.setAppElement('#rfsMapTest');
        }, []);
        const Story = () => {
            return (
                <ReactModal
                    isOpen={isShown}
                    className={styles.modal}
                    overlayClassName={styles.background}
                    onAfterClose={onClose}
                    onRequestClose={onClose}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className={styles.wrapper}>
                        <div className={styles.closeButtonWrapper}>
                            <button onClick={onClose} className={styles.closeButton}>
                                <A11yText>Zamknij Popup</A11yText>
                            </button>
                        </div>
                        {children}
                    </div>
                </ReactModal>
            );
        }

        return (
            <Story/>
        );
    }

export default Modal;
