import React, {useState} from 'react';
import styles from './PopupBody.module.scss';
import {analyticsDispatchEvent} from "../../common/utils/analyticsDispatchEvent";


const PopupBody = () => {
    const [opinion, setOpinion] = useState(false);

    const yesOpinion = () => {
        setOpinion(true);
        analyticsDispatchEvent("event-click", {
            events: "event120",
            eVar139: "map;yes"
        });
    }

    const noOpinion = () => {
        setOpinion(true);
        analyticsDispatchEvent("event-click", {
            events: "event120",
            eVar139: "map;no"
        });
    }

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.heading}>{opinion ? 'Dziękujemy' : 'Zmieniamy się dla Was!'}</h2>
            {!opinion && (
                <>
                    <p className={styles.text}>
                        Pracujemy nad wdrożeniem mapy dostępności usług UPC.<br/>
                        Przepraszamy, że jeszcze nie jest dostępna.
                    </p>
                    <p className={styles.text}>
                        Uważasz, że sprawdzenie dostępności usług UPC na mapie byłoby dla Ciebie pomocne?
                    </p>
                </>
            )}
            {opinion && (
                <p className={styles.thankYouText}>
                    Twoja odpowiedź bardzo nam się przyda do dalszej pracy nad stroną.
                </p>
            )}
            {!opinion && (
                <div className={styles.buttons}>
                    <button className={styles.button} onClick={yesOpinion}>
                        <p>Tak</p>
                    </button>
                    <button className={styles.button} onClick={noOpinion}>
                        <p>Nie</p>
                    </button>
                </div>
            )}
        </div>
    );
}

export default PopupBody;
