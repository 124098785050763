import React from 'react';
import Popup from "./components/Popup/Popup";
import PopupBody from "./components/PopupBody/PopupBody";
import {analyticsDispatchEvent} from "./common/utils/analyticsDispatchEvent";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            isLoaded: false,
        };
    }

    clickAction = (button) => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup,
        }));
        analyticsDispatchEvent("event-click", {
            events: "event120",
            eVar139: "map;clicked"
        });
    }

    detectButton() {
        //disable all previous AEM events on button and detect all click
        const buttons = document.getElementsByClassName('rfsMapButton');

        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', (event) => {
                this.clickAction(buttons[i]);
            });
            buttons[i].addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
            });
        }
    }


    componentWillMount() {
        this.detectButton();
    }

    render() {
        return (
            <Popup
                onClose={() => {
                    if (this.state.showPopup === true) {
                        analyticsDispatchEvent("event-click", {
                            events: "event120",
                            eVar139: "map;close"
                        });
                    }
                    this.setState(prevState => ({showPopup: false}))
                }}
                showPopup={this.state.showPopup}>
                <PopupBody/>
            </Popup>
        )
    }
}

export default Main;
